import * as React from "react";
import {useEffect, useState} from "react";
import * as THREE from 'three'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {TOKENS_SERVICE_URL} from "../../other/api";
import {useParams} from "react-router-dom";
import {OutlineEffect} from "three/examples/jsm/effects/OutlineEffect";

const Sneaker3d = () => {
    let {id} = useParams();
    const [img, setImg] = useState<any>(null);

    const [x, setX] = useState<number>(0);
    const [y, setY] = useState<number>(0);
    const [z, setZ] = useState<number>(0);

    const loadLayer = async (loader: any, scene: any, i: number) => {
        try {
            await loader.loadAsync(`${TOKENS_SERVICE_URL}/sneaker3d/gltf/${id}/${i}`).then((gltf: any) => {
                gltf.scene.rotation.x = Math.PI / 20;
                scene.add(gltf.scene);
            });
        } catch (e) {
        }
    }

    const loadData = async () => {
        const camera = new THREE.PerspectiveCamera(45, 1, 0.25, 20);
        camera.position.set(-1.8753634118754743, 0.7149575155845191, 0.8623182539929302);

        const renderer = new THREE.WebGLRenderer({antialias: false, alpha: true, preserveDrawingBuffer: true});
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(600, 600);
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        renderer.toneMappingExposure = 1;
        renderer.setClearColor( 0x111100, 0 );


        const effect = new OutlineEffect(renderer, {
            defaultThickness: 0.01
        });

        const scene = new THREE.Scene();
        const loader = new GLTFLoader();
        let loadTasks = [];
        for (let i = 1; i < 7; i++) {
            loadTasks.push(loadLayer(loader, scene, i));
        }
        
        await Promise.all(loadTasks);
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.addEventListener('change', (e) => {
            setX(camera.position.x);
            setY(camera.position.y);
            setZ(camera.position.z);
            renderer.render(scene, camera);
            effect.render(scene, camera);
        }); // use if there is no animation loop
        controls.minDistance = 2;
        controls.maxDistance = 10;
        controls.target.set(1, 0, -0.3);
        controls.update();
        
        
        const light = new THREE.AmbientLight(0xffffff, 2);
        scene.add(light);
        scene.add(camera);
        renderer.render(scene, camera);
        effect.render(scene, camera);


        const container = document.getElementById('CanvasFrame');
        container?.appendChild(renderer.domElement);

        const strMime = "image/webp";
        setImg(renderer.domElement.toDataURL(strMime, 1.0));
    }

    useEffect(() => {

        loadData();
    }, []);
    return (
        <>
            <p>X = {x}, Y = {y}, Z = {z}</p>
            <div id='CanvasFrame'>

            </div>
            {img && <img id='sneakerImage' src={img}/>}
            {img && <p id='sneakerImageContentType'>image/webp</p>}
        </>

    );
};

export {Sneaker3d};
