import * as React from "react";
import {useEffect, useState} from "react";
import {useAuth} from "../auth/authProvider";
import {
    Button,
    Card,
    Checkbox,
    Col, DatePicker,
    Input,
    InputNumber,
    Modal,
    notification, Popconfirm,
    Row,
    Select,
    Space,
    Spin,
    Table
} from "antd";
import axiosAdminInstance from "../../other/api";
import type {ColumnsType} from "antd/es/table";
import {DeleteOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {Currencies} from "../../other/constraints";

const AffiliateAchievements = () => {
    const {user, logout} = useAuth();
    const [loading, setLoading] = useState(false);
    const [achievements, setAchievements] = useState<any[]>([]);
    const [editAchievement, setEditAchievement] = useState<any>(null);
    const [api, contextHolder] = notification.useNotification();

    const columns: ColumnsType<any> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Refees count',
            dataIndex: 'activeRefeesCount',
            key: 'activeRefeesCount'
        },
        {
            title: 'Payment currency',
            dataIndex: 'seedPaymentCurrency',
            key: 'seedPaymentCurrency'
        },
        {
            title: 'Payment amount',
            dataIndex: 'seedPaymentAmount',
            key: 'seedPaymentAmount'
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space size="middle">
                    <a onClick={() => setEditAchievement(record)}>Edit</a>

                    <Popconfirm
                        title="Delete"
                        description="Are you sure?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={async () => await deleteAchievement(record.id)}
                    >
                        <Button
                           danger
                            icon={<DeleteOutlined/>}
                        >

                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    const load = async () => {
        try {
            setLoading(true);
            const res = await axiosAdminInstance.get(`/affiliate/achievements`);
            setAchievements(res.data);

        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    const changeValue = (key: string, value: any) => {
        const record = {...editAchievement};
        record[key] = value;
        setEditAchievement(record);
    }

    const update = async () => {
        try {
            setLoading(true);
            await axiosAdminInstance.put(`/affiliate/achievements`, editAchievement);
            api['success']({
                message: 'Saved'
            });
            setEditAchievement(null);
            await load();
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    const deleteAchievement = async (id: any) => {
        try {
            setLoading(true);
            await axiosAdminInstance.delete(`/affiliate/achievements/${id}`);
            api['success']({
                message: 'Deleted'
            });
            await load();
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        load();
    }, []);


    return <div><h3>Achievements <Button onClick={()=>setEditAchievement({})}>Create</Button></h3> {contextHolder} <Row gutter={16}>
        <Table scroll={{x: 1500}} rowKey="id" loading={loading} columns={columns} dataSource={achievements}/>
    </Row>
        {editAchievement && <Modal
            title={`Edit`}
            open={!!editAchievement}
            onOk={update}
            onCancel={() => setEditAchievement(null)}
            okButtonProps={{disabled: loading}}
            cancelButtonProps={{disabled: loading}}
        >
            <div>
                <div>
                    <span>Active refees count</span>
                    <InputNumber
                        style={{width: '100%'}}
                        value={editAchievement.activeRefeesCount}
                        onChange={e => changeValue('activeRefeesCount', e)}
                    />
                </div>

                <div>
                    <span>Payment currency</span>
                    <Select
                        style={{width: '100%'}}
                        value={editAchievement.seedPaymentCurrency}
                        onChange={e => changeValue('seedPaymentCurrency', e)}
                        options={Currencies}
                    />
                </div>

                <div>
                    <span>Payment amount</span>
                    <Input
                        style={{width: '100%'}}
                        value={editAchievement.seedPaymentAmount}
                        onChange={e => changeValue('seedPaymentAmount', e.target.value)}
                    />
                </div>

            </div>


        </Modal>}
    </div>;
}

export {AffiliateAchievements};
