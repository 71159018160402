import * as React from "react";
import {ColumnsType} from "antd/es/table";
import {
    Button,
    Checkbox,
    Input,
    InputNumber,
    Modal,
    notification,
    Select,
    SelectProps,
    Space,
    Table,
    Tag,
    Tooltip
} from "antd";
import Moment from "react-moment";
import {useEffect, useState} from "react";
import {useAuth} from "../auth/authProvider";
import {CopyOutlined, DeleteOutlined, TransactionOutlined} from "@ant-design/icons";
import {FitnessTrainingWorkouts} from "./fitnessTrainingWorkouts";

const FitnessTrainings = () => {
    const {user, logout} = useAuth();
    const [loading, setLoading] = useState(false);
    const [trainings, setTrainings] = useState<any[]>([]);
    const [editTraining, setEditTraining] = useState<any>(null);
    const [localizations, setLocalizations] = useState<any[]>([]);
    const [localizationOptions, setLocalizationOptions] = useState<any[]>([]);
    const [fitnessTrainingTypeOptions, setFitnessTrainingTypeOptions] = useState<any[]>([]);
    const [api, contextHolder] = notification.useNotification();
    const trainingTypes: any = {
        1: 'FitnessTraining',
        2: 'Yoga'
    };
    const columns: ColumnsType<any> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Type',
            dataIndex: 'fitnessTrainingType',
            key: 'fitnessTrainingType',
            render: (value) => <Tag color={value == 1 ? "volcano" : "gold"}>{trainingTypes[value]}</Tag>
        },
        {
            title: 'FittyId',
            dataIndex: 'fittyId',
            key: 'fittyId',
        },
        {
            title: 'TitleKey',
            dataIndex: 'titleKey',
            key: 'titleKey',
            render: (value) => <span>{value} ({localizations[value]})</span>
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (value) => <span>{value} min</span>
        },
        {
            title: 'Bg color',
            dataIndex: 'backgroundColor',
            key: 'backgroundColor',
            render: (value) => <span style={{backgroundColor: value}}>{value}</span>
        },

        {
            title: 'Bg image Url',
            dataIndex: 'backgroundImageUrl',
            key: 'backgroundImageUrl',
            render: (value) => <img style={{maxWidth: 50}} src={value} alt={'img'}/>
        },
        {
            title: 'Difficulty description key',
            dataIndex: 'difficultyDescriptionKey',
            key: 'difficultyDescriptionKey',
            render: (value) => <span>{value} ({localizations[value]})</span>
        },
        {
            title: 'Description key',
            dataIndex: 'descriptionKey',
            key: 'descriptionKey',
            render: (value) => <span>{value} ({localizations[value]})</span>
        },
        {
            title: 'Duration description key',
            dataIndex: 'durationDescriptionKey',
            key: 'durationDescriptionKey',
            render: (value) => <span>{value} ({localizations[value]})</span>
        },
        {
            title: 'Subscription only',
            dataIndex: 'subscriptionOnlyAccess',
            key: 'subscriptionOnlyAccess',
            render: (value) => <Checkbox defaultChecked={value} disabled/>
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (value) => <Checkbox defaultChecked={value} disabled/>
        },
        {
            title: 'Action',
            key: 'action',
            fixed: "right",
            render: (record) => (
                <Space size="middle">
                    <a onClick={() => setEditTraining(record)}>Edit</a>
                    <Button
                        type="primary"
                        icon={<CopyOutlined/>}
                        onClick={() => {
                            let copyObj = {...record};
                            copyObj.id = 0;
                            setEditTraining(copyObj);
                        }}
                    >

                    </Button>
                    <Button
                        type="primary"
                        icon={<DeleteOutlined/>}
                        onClick={async () => await deleteTraining(record.id)}
                    >

                    </Button>
                </Space>
            ),
        },
    ];

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const load = async () => {
        try {
            // setLoading(true);
            // const localizations = await getAsync(`/localization`);
            // const options: SelectProps['options'] = [{value: null, label: '-'}];
            // let localizationObject: any = {};
            // localizations.forEach((item: any) => {
            //     localizationObject[item.key] = item.en;
            //     options.push({
            //         value: item.key,
            //         label: `${item.key} (${item.en})`
            //     });
            // });
            // const trainingTypeOptions: SelectProps['options'] = [];
            // Object.keys(trainingTypes).forEach(key => {
            //     trainingTypeOptions.push({
            //         value: +key,
            //         label: trainingTypes[key]
            //     });
            // })
            // setFitnessTrainingTypeOptions(trainingTypeOptions);
            // setLocalizations(localizationObject);
            // setLocalizationOptions(options);

            // const res = await getAsync(`/fitness-trainings`);
            // setTrainings(res);
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    const saveFitnessTraining = async () => {
        try {
            // setLoading(true);
            // if (!editTraining.id || editTraining.id == 0) {
            //     await postAsync(`/fitness-trainings`, editTraining);
            // } else {
            //     await putAsync(`/fitness-trainings`, editTraining);
            // }
            //
            // const trainings = await getAsync(`/fitness-trainings`);
            // setTrainings(trainings);
            // setEditTraining(null);
            // api['success']({
            //     message: 'Saved'
            // });
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    const reloadTrainings = async () => {
        setLoading(true);
        // const trainings = await getAsync(`/fitness-trainings`);
        // setTrainings(trainings);
        // setLoading(false);
    }

    const deleteTraining = async (key: string) => {
        try {
            setLoading(true);
            // deleteAsync(`/fitness-trainings/${key}`).then(async r => {
            //     const trainings = await getAsync(`/fitness-trainings`);
            //     setTrainings(trainings);
            // });
            api['success']({
                message: 'Saved'
            });
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        load();
    }, []);

    const changeValue = (key: string, value: any) => {
        const record = {...editTraining};
        record[key] = value;
        setEditTraining(record);
    }

    return <>
        <h2>Fitness trainings <Button onClick={() => setEditTraining({})}>Create</Button></h2>
        {contextHolder}
        <Table loading={loading} rowKey="id" columns={columns} dataSource={trainings}
               scroll={{x: 1500}}
               expandable={{
                   expandedRowRender: (record) => <div>
                       <FitnessTrainingWorkouts trainingId={record.id} trainingWorkouts={record.fitnessTrainingWorkouts}
                                                localizations={localizations} localizationOptions={localizationOptions}
                                                reloadData={reloadTrainings}/>
                   </div>,
                   rowExpandable: (record) => true,
               }}
               size="small"/>
        {editTraining && <Modal
            title={`Training details`}
            open={!!editTraining}
            onOk={saveFitnessTraining}
            onCancel={() => setEditTraining(null)}
            okButtonProps={{disabled: loading}}
            cancelButtonProps={{disabled: loading}}
        >
            <div>

                <div>
                    <span>Key</span>
                    <Input
                        placeholder="Fitty id"
                        value={editTraining.fittyId}
                        onChange={e => changeValue('fittyId', e.target.value)}
                    />
                </div>

                <div>
                    <span>Title key</span>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        style={{width: '100%'}}
                        value={editTraining.titleKey}
                        onChange={e => changeValue('titleKey', e)}
                        options={localizationOptions}
                    />

                </div>

                <div>
                    <span>Duration</span>
                    <InputNumber
                        style={{width: '100%'}}
                        value={editTraining.duration}
                        onChange={e => changeValue('duration', e)}
                    />

                </div>

                <div>
                    <span>Background color</span>
                    <Input
                        placeholder="Background color hex"
                        value={editTraining.backgroundColor}
                        onChange={e => changeValue('backgroundColor', e.target.value)}
                    />

                </div>

                <div>
                    <span>Background image url</span>
                    <Input
                        placeholder="Background image url"
                        value={editTraining.backgroundImageUrl}
                        onChange={e => changeValue('backgroundImageUrl', e.target.value)}
                    />
                </div>

                <div>
                    <span>Difficulty description key</span>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        style={{width: '100%'}}
                        value={editTraining.difficultyDescriptionKey}
                        onChange={e => changeValue('difficultyDescriptionKey', e)}
                        options={localizationOptions}
                    />

                </div>

                <div>
                    <span>Description key</span>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        style={{width: '100%'}}
                        value={editTraining.descriptionKey}
                        onChange={e => changeValue('descriptionKey', e)}
                        options={localizationOptions}
                    />

                </div>

                <div>
                    <span>Duration description key</span>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        style={{width: '100%'}}
                        value={editTraining.durationDescriptionKey}
                        onChange={e => changeValue('durationDescriptionKey', e)}
                        options={localizationOptions}
                    />

                </div>

                <div>
                    <span>Training type</span>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        style={{width: '100%'}}
                        value={editTraining.fitnessTrainingType}
                        onChange={e => changeValue('fitnessTrainingType', e)}
                        options={fitnessTrainingTypeOptions}
                    />

                </div>

                <div>
                    <span>Subscription only access</span>
                    <Checkbox
                        checked={editTraining.subscriptionOnlyAccess}
                        value={editTraining.subscriptionOnlyAccess}
                        onChange={e => changeValue('subscriptionOnlyAccess', e.target.checked)}
                    />
                </div>

                <div>
                    <span>Active</span>
                    <Checkbox
                        checked={editTraining.active}
                        value={editTraining.active}
                        onChange={e => changeValue('active', e.target.checked)}
                    />
                </div>
            </div>

        </Modal>}
    </>;
}

export {FitnessTrainings};
