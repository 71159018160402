import * as React from 'react';
import {Routes, Route, Outlet, NavLink} from 'react-router-dom';
import {
    DollarOutlined,
    DashboardOutlined,
    RocketOutlined,
    FundProjectionScreenOutlined,
    MehOutlined,
    LoginOutlined,
    TransactionOutlined,
    ThunderboltOutlined,
    UserSwitchOutlined,
    GiftOutlined,
    PercentageOutlined, PoundOutlined,
} from '@ant-design/icons';
import {Layout, Menu, Button, theme} from 'antd';
import {Dashboard} from "./pages/dashboard/dashboard";
import {FitnessTrainings} from "./pages/fitnessTrainings/fitnessTrainings";
import {AuthProvider, useAuth} from "./pages/auth/authProvider";
import {ProtectedRoute} from "./pages/auth/protectedRoute";
import {Auth} from "./pages/auth/auth";
import {Localization} from "./pages/localization/localization";
import {Users} from "./pages/users/users";
import {AppVersion} from "./pages/appVersion/appVersion";
import {PartnerSales} from "./pages/partnerSales/partnerSales";
import {Sales} from "./pages/sales/sales";
import {UserDetails} from "./pages/users/userDetails";
import {Affiliate} from "./pages/affiliate/affiliate";
import {HotWallets} from "./pages/hotWallets/hotWallets";
import {Sneaker3d} from "./pages/3d/sneaker3d";

const {Header, Sider, Content} = Layout;

console.log(process.env);
const App = () => {
    return (
        <AuthProvider>
            <AppLayout/>
        </AuthProvider>
    );
};

const AppLayout = () => {
    const {user, logout} = useAuth();
    return (
        <Layout>
            <Sider trigger={null} collapsible collapsed={false}>
                <div className="demo-logo-vertical"/>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    items={!user ? [] : [
                        {
                            key: '1',
                            icon: <DashboardOutlined/>,
                            label: <NavLink to="/dashboard">
                                Dashboard
                            </NavLink>,
                        },
                        {
                            key: '2',
                            icon: <UserSwitchOutlined/>,
                            label: <NavLink to="/users">
                                Users
                            </NavLink>
                        },
                        {
                            key: '3',
                            icon: <TransactionOutlined/>,
                            label: <NavLink to="/localization">
                                Localization
                            </NavLink>
                        },
                        {
                            key: '4',
                            icon: <GiftOutlined />,
                            label: <NavLink to="/partners">
                                Partners sales
                            </NavLink>
                        },
                        {
                            key: '5',
                            icon: <GiftOutlined />,
                            label: <NavLink to="/sales">
                                Sales
                            </NavLink>
                        },
                        {
                            key: '6',
                            icon: <PercentageOutlined />,
                            label: <NavLink to="/affiliate">
                                Affiliate
                            </NavLink>
                        },
                        {
                            key: '7',
                            icon: <PoundOutlined />,
                            label: <NavLink to="/hotWallets">
                                Hot wallets
                            </NavLink>
                        },
                        // {
                        //     key: '6',
                        //     icon: <ThunderboltOutlined/>,
                        //     label: <NavLink to="/users">
                        //         Users
                        //     </NavLink>
                        // },
                        //
                        // {
                        //     key: '5',
                        //     label: <NavLink to="/app-versions">
                        //         App versions
                        //     </NavLink>
                        // },
                        {
                            key: '10',
                            icon: <LoginOutlined/>,
                            label: <div onClick={logout}>Logout</div>
                        },
                    ]}
                />
            </Sider>
            <Layout>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: '100vh',
                        background: 'white',
                    }}
                >
                    <Routes>

                        <Route index element={<ProtectedRoute><Dashboard/></ProtectedRoute>}/>
                        <Route path="login" element={<Auth/>}/>
                        <Route path="sneaker3d/:id" element={<Sneaker3d/>}/>
                        <Route path="dashboard" element={<ProtectedRoute><Dashboard/></ProtectedRoute>}/>
                        <Route path="users" element={<ProtectedRoute><Users/></ProtectedRoute>}/>
                        <Route path="user/:id" element={<ProtectedRoute><UserDetails/></ProtectedRoute>}/>
                        <Route path="localization" element={<ProtectedRoute><Localization/></ProtectedRoute>}/>
                        <Route path="trainigs" element={<ProtectedRoute><FitnessTrainings/></ProtectedRoute>}/>
                        <Route path="app-versions" element={<ProtectedRoute><AppVersion/></ProtectedRoute>}/>
                        <Route path="partners" element={<ProtectedRoute><PartnerSales/></ProtectedRoute>}/>
                        <Route path="sales" element={<ProtectedRoute><Sales/></ProtectedRoute>}/>
                        <Route path="affiliate" element={<ProtectedRoute><Affiliate/></ProtectedRoute>}/>
                        <Route path="hotWallets" element={<ProtectedRoute><HotWallets/></ProtectedRoute>}/>

                        <Route path="*" element={<p>There's nothing here: 404!</p>}/>
                    </Routes>

                </Content>
            </Layout>
        </Layout>
    );
}

export default App;
