import * as React from "react";
import {ColumnsType} from "antd/es/table";
import {
    Button,
    Checkbox,
    Input,
    InputNumber,
    Modal,
    notification,
    Select,
    SelectProps,
    Space,
    Table,
    Tag,
    Tooltip
} from "antd";
import Moment from "react-moment";
import {useEffect, useState} from "react";
import {useAuth} from "../auth/authProvider";
import {CopyOutlined, DeleteOutlined, TransactionOutlined} from "@ant-design/icons";

const FitnessTrainingWorkouts = ({
                                     trainingId,
                                     trainingWorkouts,
                                     localizations,
                                     reloadData,
                                     localizationOptions
                                 }: any) => {
    const {user, logout} = useAuth();
    const [loading, setLoading] = useState(false);
    const [workouts, setWorkouts] = useState<any[]>(trainingWorkouts);
    const [editWorkout, setEditWorkout] = useState<any>(null);
    const [api, contextHolder] = notification.useNotification();

    const columns: ColumnsType<any> = [
        {
            title: 'Sequence',
            dataIndex: 'sequence',
            key: 'sequence'
        },
        {
            title: 'TitleKey',
            dataIndex: 'titleKey',
            key: 'titleKey',
            render: (value) => <span>{value} ({localizations[value]})</span>
        }, {
            title: 'Description key',
            dataIndex: 'descriptionKey',
            key: 'descriptionKey',
            render: (value) => <span>{value} ({localizations[value]})</span>
        },
        {
            title: 'Preview image Url',
            dataIndex: 'previewImgUrl',
            key: 'previewImgUrl',
            render: (value) => <img style={{maxWidth: 100}} src={value} alt={'img'}/>
        },
        {
            title: 'Video Url',
            dataIndex: 'videoUrl',
            key: 'videoUrl',
            render: (value) => <video style={{maxWidth: 100}} src={value}/>
        },
        {
            title: 'GroupKey',
            dataIndex: 'groupKey',
            key: 'groupKey',
            render: (value) => <span>{value} ({localizations[value]})</span>
        },
        {
            title: 'Fitty index',
            dataIndex: 'fittyAiIndex',
            key: 'fittyAiIndex',
        },
        {
            title: 'Repetitions',
            dataIndex: 'reps',
            key: 'reps',
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space size="middle">
                    <a onClick={() => setEditWorkout(record)}>Edit</a>
                    <Button
                        type="primary"
                        icon={<CopyOutlined/>}
                        onClick={() => {
                            let copyObj = {...record};
                            copyObj.id = 0;
                            copyObj.sequence = record.sequence + 1;
                            copyObj.fittyAiIndex = record.fittyAiIndex + 1;
                            setEditWorkout(copyObj);
                        }}
                    >

                    </Button>
                    <Button
                        type="primary"
                        icon={<DeleteOutlined/>}
                        onClick={async () => await deleteTraining(record.id)}
                    >

                    </Button>
                </Space>
            ),
        },
    ];

    const saveWorkout = async () => {
        try {
            setLoading(true);
            // if (!editWorkout.id || editWorkout.id == 0) {
            //     await postAsync(`/fitness-trainings/workouts`, {...editWorkout, fitnessTrainingId: trainingId});
            // } else {
            //     await putAsync(`/fitness-trainings/workouts`, {...editWorkout, fitnessTrainingId: trainingId});
            // }
            setEditWorkout(null);
            reloadData();
            api['success']({
                message: 'Saved'
            });
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    const deleteTraining = async (key: string) => {
        try {
            setLoading(true);
            // deleteAsync(`/fitness-trainings/workouts/${key}`).then(async r => {
            //     reloadData();
            // });
            api['success']({
                message: 'Saved'
            });
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    const changeValue = (key: string, value: any) => {
        const record = {...editWorkout};
        record[key] = value;
        setEditWorkout(record);
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return <>
        <h2>Workouts <Button onClick={() => setEditWorkout({sequence: trainingWorkouts.length + 1})}>Create</Button>
        </h2>
        <Table loading={loading} rowKey="id" columns={columns} dataSource={trainingWorkouts}
               size="small"/>
        {editWorkout && <Modal
            title={`Workout details`}
            open={!!editWorkout}
            onOk={saveWorkout}
            onCancel={() => setEditWorkout(null)}
            okButtonProps={{disabled: loading}}
            cancelButtonProps={{disabled: loading}}
        >
            <div>

                <div>
                    <span>Sequence</span>
                    <InputNumber
                        style={{width: '100%'}}
                        value={editWorkout.sequence}
                        onChange={e => changeValue('sequence', e)}
                    />

                </div>

                <div>
                    <span>Title key</span>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        style={{width: '100%'}}
                        value={editWorkout.titleKey}
                        onChange={e => changeValue('titleKey', e)}
                        options={localizationOptions}
                    />

                </div>

                <div>
                    <span>Description key</span>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        style={{width: '100%'}}
                        value={editWorkout.descriptionKey}
                        onChange={e => changeValue('descriptionKey', e)}
                        options={localizationOptions}
                    />

                </div>

                <div>
                    <span>Group key</span>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        style={{width: '100%'}}
                        value={editWorkout.groupKey}
                        onChange={e => changeValue('groupKey', e)}
                        options={localizationOptions}
                    />

                </div>

                <div>
                    <span>Preview image url</span>
                    <Input
                        placeholder="Preview image url"
                        value={editWorkout.previewImgUrl}
                        onChange={e => changeValue('previewImgUrl', e.target.value)}
                    />
                </div>

                <div>
                    <span>Video url</span>
                    <Input
                        placeholder="Video url"
                        value={editWorkout.videoUrl}
                        onChange={e => changeValue('videoUrl', e.target.value)}
                    />
                </div>

                <div>
                    <span>Fitty index</span>
                    <InputNumber
                        style={{width: '100%'}}
                        value={editWorkout.fittyAiIndex}
                        onChange={e => changeValue('fittyAiIndex', e)}
                    />
                </div>

                <div>
                    <span>Repetitions</span>
                    <InputNumber
                        style={{width: '100%'}}
                        value={editWorkout.reps}
                        onChange={e => changeValue('reps', e)}
                    />
                </div>


            </div>

        </Modal>}
    </>;
}

export {FitnessTrainingWorkouts};
