import axios from 'axios'

export const AUTH_SERVICE_URL = "/auth";
//export const AUTH_SERVICE_URL = "http://localhost:54311";

const axiosAuthInstance = axios.create({
    baseURL: AUTH_SERVICE_URL
    // other configurations
})

export default  axiosAuthInstance;