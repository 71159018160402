import * as React from "react";
import type {ColumnsType} from 'antd/es/table';
import {Button, Col, Form, Input, InputNumber, Modal, notification, Row, Space, Spin, Switch, Table, Tag} from "antd";
import {useEffect, useState} from "react";
import axiosAdminInstance from "../../other/api";
import {CopyOutlined} from "@ant-design/icons";
import {NavLink} from "react-router-dom";

const Users = () => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<any[]>([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItemsCount, setTotalItemsCount] = useState(10);
    const [api, contextHolder] = notification.useNotification();

    const columns: ColumnsType<any> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: record => <NavLink to={`/user/${record}`}>
                {record}
            </NavLink>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Shard',
            dataIndex: 'realmId',
            key: 'realmId'
        },
        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: (record) => (
        //         <Space size="middle">
        //             <Button
        //                 type="primary"
        //                 onClick={() => {
        //                    
        //                 }}
        //             >Profile</Button>
        //         </Space>
        //     ),
        // },
    ];

    const loadRecords = async () => {
        try {
            setLoading(true);
            const res = await axiosAdminInstance.post(`/users/search`, {email: search});
            setUsers(res.data);
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadRecords();
    }, []);

    return (
        <>
            <h2>Users</h2>
            {contextHolder}
            <Row gutter={8}>
                <Col span={12}>
                    <Input
                        placeholder="Email"
                        value={search}
                        onChange={e => {
                            const currValue = e.target.value;
                            setSearch(currValue);
                        }}
                    />
                </Col>
                <Col span={12}>
                    <Button onClick={loadRecords}>Search</Button>
                </Col>
            </Row>


            <Table rowKey="id"
                   loading={loading} columns={columns} dataSource={users}/>
        </>
    );
};

export {Users};
