import * as React from "react";
import type {ColumnsType} from 'antd/es/table';
import {
    Button,
    Checkbox, Col,
    Form,
    Input,
    InputNumber,
    Modal,
    notification, Row, Select,
    SelectProps,
    Space,
    Spin,
    Switch,
    Table,
    Tag,
    DatePicker, DatePickerProps
} from "antd";
import {useEffect, useState} from "react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {useAuth} from "../auth/authProvider";
import {CopyOutlined, DeleteOutlined, LockOutlined, TransactionOutlined} from "@ant-design/icons";
import moment from "moment";
import axiosAdminInstance from "../../other/api";
import {Typography} from 'antd';
import TextArea from "antd/es/input/TextArea";

const {Title} = Typography;

const {RangePicker} = DatePicker;
dayjs.extend(utc)
const Sales = () => {
    const [loading, setLoading] = useState(false);
    const [fromJsonPartner, setFromJsonPartner] = useState<any>(null);
    const [sales, setSales] = useState<any[]>([]);
    const [salesFiltered, setSalesFiltered] = useState<any[]>([]);
    const [localizations, setLocalizations] = useState<any[]>([]);
    const [localizationOptions, setLocalizationOptions] = useState<any[]>([]);
    const [editPartner, setEditPartner] = useState<any>(null);
    const [search, setSearch] = useState('');
    const [totalItemsCount, setTotalItemsCount] = useState(10);
    const [api, contextHolder] = notification.useNotification();
    const [platforms, setPlatforms] = useState<any[]>([
        {
            value: 1,
            label: 'Android'
        },
        {
            value: 2,
            label: 'Ios'
        },
        {
            value: 3,
            label: 'All'
        }
    ]);

    const columns: ColumnsType<any> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (value) => <span>{value} ({localizations[value]})</span>
        },
        {
            title: 'Group',
            dataIndex: 'groupName',
            key: 'groupName',
            render: (value) => <span>{value} ({localizations[value]})</span>
        },
        {
            title: 'Image',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (value) => <img style={{maxWidth: 50}} src={`https://cdn.step.app/content/raw_webp/${value}.webp`}
                                    alt={'img'}/>
        },

        {
            title: 'Bg image',
            dataIndex: 'bannerBackgroundImageUrl',
            key: 'bannerBackgroundImageUrl',
            render: (value) => <img style={{maxWidth: 50}} src={`https://cdn.step.app/content/raw_webp/${value}.webp`}
                                    alt={'img'}/>
        },
        {
            title: 'Active',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (value) => <Checkbox defaultChecked={value} disabled/>
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space size="middle">
                    <a onClick={() => setEditPartner(record)}>Edit</a>
                    <Button
                        type="primary"
                        icon={<CopyOutlined/>}
                        onClick={() => {
                            let copyObj = {...record};
                            copyObj.id = 0;
                            setEditPartner(copyObj);
                        }}
                    />
                    {/*<Button*/}
                    {/*    type="primary"*/}
                    {/*    icon={<DeleteOutlined/>}*/}
                    {/*    onClick={async () => await deletePartner(record.id)}*/}
                    {/*>*/}

                    {/*</Button>*/}
                </Space>
            ),
        },
    ];

    const deletePartner = async (id: any) => {

    }

    const loadRecords = async () => {
        try {
            setLoading(true);
            const res = await axiosAdminInstance.get(`/sales`);
            const localizations = await axiosAdminInstance.get(`/localization`);
            const options: SelectProps['options'] = [{value: null, label: '-'}];
            let localizationObject: any = {};
            localizations.data.forEach((item: any) => {
                localizationObject[item.key] = item.en;
                options.push({
                    value: item.key,
                    label: `${item.key} (${item.en})`
                });
            });
            setLocalizations(localizationObject);
            setLocalizationOptions(options);
            setSales(res.data);
            setSalesFiltered(res.data);
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadRecords();
    }, []);


    const addDescription = () => {
        const record = {...editPartner};
        if (!record.description) {
            record.description = [""];
        } else {
            record.description = [...record.description].concat([""]);
        }
        setEditPartner(record);
    }

    const deleteDescription = (index: number) => {
        const record = {...editPartner};
        record.description.splice(index, 1);
        setEditPartner(record);
    }

    const savePartner = async () => {
        try {
            setLoading(true);
            if (!editPartner.id || editPartner.id == 0) {
                await axiosAdminInstance.post(`/sales`, editPartner);
            } else {
                await axiosAdminInstance.put(`/sales`, editPartner);
            }
            api['success']({
                message: 'Saved'
            });
            setEditPartner(null);
            await loadRecords();
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const changeValue = (key: string, value: any) => {
        const record = {...editPartner};
        record[key] = value;
        setEditPartner(record);
    }

    const changeArrayValue = (key: string, index: number, value: any) => {
        const record = {...editPartner};
        record[key][index] = value;
        setEditPartner(record);
    }

    const createPartnerFromJson = () => {
        setFromJsonPartner(null);
        const item = JSON.parse(fromJsonPartner.value);
        item.id = 0;
        setEditPartner(item);
    }

    const filterData = (key: string) => {
        const filteredData = sales.filter(entry =>
            (!key || entry.name.toLowerCase().includes(key.toLowerCase()))
        );
        setSalesFiltered(filteredData);
    }

    return (
        <>
            <h2>Sales <Space>
                <Button
                    onClick={() => {
                        setFromJsonPartner({});
                    }}
                >
                    Create from JSON
                </Button><Button
                onClick={() => {
                    setEditPartner({});
                }}
            >
                Create
            </Button>
                <Input
                    placeholder="Search"
                    value={search}
                    onChange={e => {
                        const currValue = e.target.value;
                        setSearch(currValue);
                        filterData(currValue);
                    }}
                />
            </Space></h2>
            {contextHolder}

            <Table scroll={{x: 1500}} rowKey="id" loading={loading} columns={columns} dataSource={salesFiltered}/>

            {fromJsonPartner && <Modal
                title={`Create from JSON`}
                open={!!fromJsonPartner}
                onOk={createPartnerFromJson}
                onCancel={() => setFromJsonPartner(null)}
            >
                <div>
                    <span>JSON</span>
                    <TextArea rows={4}
                              placeholder="Json"
                              value={fromJsonPartner.value}
                              onChange={e => setFromJsonPartner({value: e.target.value})}
                    />
                </div>
            </Modal>}

            {editPartner && <Modal
                width={1000}
                title={`Sale details`}
                open={!!editPartner}
                onOk={savePartner}
                onCancel={() => setEditPartner(null)}
                okButtonProps={{disabled: loading}}
                cancelButtonProps={{disabled: loading}}
            >
                <div>
                    <div>
                        <span>Group name</span>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            style={{width: '100%'}}
                            value={editPartner.groupName}
                            onChange={e => changeValue('groupName', e)}
                            options={localizationOptions}
                        />
                    </div>

                    <div>
                        <span>Subgroup name</span>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            style={{width: '100%'}}
                            value={editPartner.subGroupName}
                            onChange={e => changeValue('subGroupName', e)}
                            options={localizationOptions}
                        />
                    </div>

                    <div>
                        <span>Group sequence</span>
                        <InputNumber
                            style={{width: '100%'}}
                            value={editPartner.groupSequence}
                            onChange={e => changeValue('groupSequence', e)}
                        />
                    </div>

                    <div>
                        <span>Sale sequence</span>
                        <InputNumber
                            style={{width: '100%'}}
                            value={editPartner.saleSequence}
                            onChange={e => changeValue('saleSequence', e)}
                        />
                    </div>

                    <div>
                        <span>Asset name</span>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            style={{width: '100%'}}
                            value={editPartner.assetName}
                            onChange={e => changeValue('assetName', e)}
                            options={localizationOptions}
                        />
                    </div>

                    <div>
                        <span>Asset rarity</span>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            style={{width: '100%'}}
                            value={editPartner.assetRarity}
                            onChange={e => changeValue('assetRarity', e)}
                            options={localizationOptions}
                        />
                    </div>

                    <div>
                        <span>More details text</span>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            style={{width: '100%'}}
                            value={editPartner.moreDetailsText}
                            onChange={e => changeValue('moreDetailsText', e)}
                            options={localizationOptions}
                        />
                    </div>

                    <div>
                        <span>Name</span>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            style={{width: '100%'}}
                            value={editPartner.name}
                            onChange={e => changeValue('name', e)}
                            options={localizationOptions}
                        />
                    </div>


                    <div>
                        <span>Available platform</span>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            style={{width: '100%'}}
                            value={editPartner.availablePlatform}
                            onChange={e => changeValue('availablePlatform', e)}
                            options={platforms}
                        />
                    </div>

                    <div>
                        <span>Has global discount</span>
                        <Checkbox
                            checked={editPartner.hasGlobalDiscount}
                            value={editPartner.hasGlobalDiscount}
                            onChange={e => changeValue('hasGlobalDiscount', e.target.checked)}
                        />
                    </div>
                    <div>
                        <span>Discount title</span>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            style={{width: '100%'}}
                            value={editPartner.discountTitle}
                            onChange={e => changeValue('discountTitle', e)}
                            options={localizationOptions}
                        />
                    </div>


                    <div style={{marginTop: 10}}>
                        <span>Discount end date</span>
                        <div>
                            <DatePicker defaultValue={dayjs(editPartner.discountEndTimestamp)} showTime
                                        onChange={(value, dateString) => {
                                            // @ts-ignore
                                            changeValue('discountEndTimestamp', dayjs.utc(value).toDate().toISOString())
                                        }}/>
                        </div>
                    </div>

                    <div>
                        <span>Global discount percent</span>
                        <InputNumber
                            style={{width: '100%'}}
                            value={editPartner.globalDiscountPercentage}
                            onChange={e => changeValue('globalDiscountPercentage', e)}
                        />
                    </div>

                    <div>
                        <span>New user discount percent</span>
                        <InputNumber
                            style={{width: '100%'}}
                            value={editPartner.userDiscountPercentage}
                            onChange={e => changeValue('userDiscountPercentage', e)}
                        />
                    </div>


                    <div>
                        <span>Background Color</span>
                        <Input
                            style={{width: '100%'}}
                            value={editPartner.backgroundColor}
                            onChange={e => changeValue('backgroundColor', e.target.value)}
                        />
                    </div>

                    <div>
                        <span>Image asset name</span>
                        <Row>
                            <Col span={16}>
                                <Input
                                    style={{width: '100%'}}
                                    value={editPartner.imageUrl}
                                    onChange={e => changeValue('imageUrl', e.target.value)}
                                />
                            </Col>
                            <Col span={6} style={{marginLeft: 10}}>

                                <img style={{maxWidth: 100}}
                                     src={`https://cdn.step.app/content/raw_webp/${editPartner.imageUrl}.webp`}
                                     alt={'img'}/>
                            </Col>
                        </Row>
                    </div>

                    
                    <div>
                        <span>Background asset name</span>
                        <Row>
                            <Col span={16}>
                                <Input
                                    style={{width: '100%'}}
                                    value={editPartner.bannerBackgroundImageUrl}
                                    onChange={e => changeValue('bannerBackgroundImageUrl', e.target.value)}
                                />
                            </Col>
                            <Col span={6} style={{marginLeft: 10}}>
                                <img style={{maxWidth: 100}}
                                     src={`https://cdn.step.app/content/raw_webp/${editPartner.bannerBackgroundImageUrl}.webp`}
                                     alt={'img'}/>
                            </Col>
                        </Row>


                    </div>

                    <div>
                        <span>Hot sale banner asset name</span>
                        <Row>
                            <Col span={16}>
                                <Input
                                    style={{width: '100%'}}
                                    value={editPartner.bannerImageUrl}
                                    onChange={e => changeValue('bannerImageUrl', e.target.value)}
                                />
                            </Col>
                            <Col span={6} style={{marginLeft: 10}}>

                                <img style={{maxWidth: 100}}
                                     src={`https://cdn.step.app/content/raw_webp/${editPartner.bannerImageUrl}.webp`}
                                     alt={'img'}/>
                            </Col>
                        </Row>
                    </div>


                    <div>
                        <span>General description</span>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            style={{width: '100%'}}
                            value={editPartner.generalDescription}
                            onChange={e => changeValue('generalDescription', e)}
                            options={localizationOptions}
                        />
                    </div>


                    <div>
                        <span>Description title</span>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            style={{width: '100%'}}
                            value={editPartner.descriptionTitle}
                            onChange={e => changeValue('descriptionTitle', e)}
                            options={localizationOptions}
                        />
                    </div>
                    <div style={{marginTop: 10}}>
                        <span>Descriptions list <Button onClick={addDescription}>Add</Button></span>
                        {editPartner.description && editPartner.description.map((d: any, index: number) => <Row
                            style={{width: '100%', margin: 10}}>
                            <Col span={16}><Select
                                showSearch
                                style={{width: '100%'}}
                                filterOption={filterOption}
                                value={d}
                                onChange={e => changeArrayValue('description', index, e)}
                                options={localizationOptions}
                            /> </Col>
                            <Col span={6} style={{marginLeft: 10}}>
                                <Button onClick={() => deleteDescription(index)}>Remove</Button>
                            </Col>
                        </Row>)}

                    </div>

                    <div>
                        <span>Usage title</span>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            style={{width: '100%'}}
                            value={editPartner.usageTitle}
                            onChange={e => changeValue('usageTitle', e)}
                            options={localizationOptions}
                        />
                    </div>

                    <div>
                        <span>Static price id</span>
                        <Input
                            style={{width: '100%'}}
                            value={editPartner.staticPriceId}
                            onChange={e => changeValue('staticPriceId', e.target.value)}
                        />
                    </div>

                    <div>
                        <span>Price currency</span>
                        <Input
                            style={{width: '100%'}}
                            value={editPartner.priceCurrency}
                            onChange={e => changeValue('priceCurrency', e.target.value)}
                        />
                    </div>


                    <div>
                        <span>Static reward id</span>
                        <Input
                            style={{width: '100%'}}
                            value={editPartner.staticRewardId}
                            onChange={e => changeValue('staticRewardId', e.target.value)}
                        />
                    </div>


                    <div>
                        <span>Warning message</span>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            style={{width: '100%'}}
                            value={editPartner.warningMessage}
                            onChange={e => changeValue('warningMessage', e)}
                            options={localizationOptions}
                        />
                    </div>


                    <div>
                        <span>Horizontal position</span>
                        <Checkbox
                            checked={editPartner.clientPositionIsHorizontal}
                            value={editPartner.clientPositionIsHorizontal}
                            onChange={e => changeValue('clientPositionIsHorizontal', e.target.checked)}
                        />
                    </div>

                    <div>
                        <span>Group scroll</span>
                        <Checkbox
                            checked={editPartner.groupHasScroll}
                            value={editPartner.groupHasScroll}
                            onChange={e => changeValue('groupHasScroll', e.target.checked)}
                        />
                    </div>

                    <div>
                        <span>Is active</span>
                        <Checkbox
                            checked={editPartner.isActive}
                            value={editPartner.isActive}
                            onChange={e => changeValue('isActive', e.target.checked)}
                        />
                    </div>


                    <div>
                        <span>Hot deal</span>
                        <Checkbox
                            checked={editPartner.isHotDeal}
                            value={editPartner.isHotDeal}
                            onChange={e => changeValue('isHotDeal', e.target.checked)}
                        />
                    </div>

                    <div>
                        <span>Show only for discount users</span>
                        <Checkbox
                            checked={editPartner.showOnDiscountOnly}
                            value={editPartner.showOnDiscountOnly}
                            onChange={e => changeValue('showOnDiscountOnly', e.target.checked)}
                        />
                    </div>

                    <div>
                        <span>Show only for non-discount users</span>
                        <Checkbox
                            checked={editPartner.showOnNotDiscountOnly}
                            value={editPartner.showOnNotDiscountOnly}
                            onChange={e => changeValue('showOnNotDiscountOnly', e.target.checked)}
                        />
                    </div>


                    <div>
                        <Button onClick={() => {
                            navigator.clipboard.writeText(JSON.stringify(editPartner));
                            api['success']({
                                message: 'Copied'
                            });
                        }}>Copy JSON</Button>
                    </div>

                    <p><b>Upload file instruction</b></p>
                    <p>1. Request access to google bucket</p>
                    <p>2. Upload file with .webp extension to bucket prod/content/raw_webp, for video use
                        prod/content/raw folder</p>
                    <p>3. Set asset name here without .webp extension for images, include extension for video</p>
                </div>

            </Modal>}
        </>
    );
};

export {
    Sales
};
