import * as React from "react";
import {useEffect, useState} from "react";
import {useAuth} from "../auth/authProvider";
import {Card, Col, Divider, Row, Spin} from "antd";
import {AffiliateSettings} from "./affiliateSettings";
import {AffiliateAchievements} from "./affiliateAchievements";

const Affiliate = () => {
    const {user, logout} = useAuth();
    const [loading, setLoading] = useState(false);
    const [stats, setStats] = useState<any>(null);

    const load = async () => {

    }

    useEffect(() => {
        load();
    }, []);


    return <div><Row justify="center" align="top">
        <h2>Affiliate</h2>
    </Row>
        <Divider />
        <AffiliateSettings/>
        <Divider />
        <AffiliateAchievements/>
        <Divider />
    </div>;
}

export {Affiliate};
