import * as React from "react";
import {Button, Col, Form, Input, notification, Row, Space, Spin} from "antd";
import Icon from "antd/es/icon";
import {LockOutlined} from "@ant-design/icons";
import {useState} from "react";
import {useAuth} from "./authProvider";
import axiosAuthInstance from "../../other/auth-api";

const Auth = () => {
    const [form] = Form.useForm();
    const {user, login} = useAuth();
    const [api, contextHolder] = notification.useNotification();

    const [loading, setLoading] = useState(false);
    const auth = async (authFrom: any) => {
        try {
            setLoading(true);
            const tokenResult = await axiosAuthInstance.post(`/auth/loginWithEmailPinCode`, {
                params: {
                    email: authFrom.email,
                    pinCode: authFrom.pinCode,
                    isAdminRequest: true
                }

            });

            login(tokenResult.data.result.accessToken, tokenResult.data.result.refreshToken);

        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    };

    const requestPinCode = async () => {
        const email = form.getFieldValue('email');
        try {
            setLoading(true);
            await axiosAuthInstance.post(`/auth/requestEmailPinCode`, {params: {email}});
            api['success']({
                message: 'Pin code has been sent'
            });

        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Row justify="center" align="top">
                <Col span={6}>
                    <h2>Log in </h2>
                    {contextHolder}
                    <Form
                        form={form}
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={auth}
                    >
                        <Space direction="vertical" size="middle">
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    },
                                ]}
                            >
                                <Space.Compact style={{width: '100%'}}>
                                    <Input
                                        prefix={<LockOutlined className="site-form-item-icon"/>}
                                        type="string"
                                        placeholder="Email"
                                    />
                                    <Button type="primary" onClick={() => requestPinCode()}>Send code</Button>
                                </Space.Compact>

                            </Form.Item>
                            <Form.Item
                                name="pinCode"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input email code!',
                                    },
                                ]}
                            >
                                <Input
                                    type="string"
                                    placeholder="Email code"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button"
                                        disabled={loading}>
                                    Log in {loading && <Spin/>}
                                </Button>
                            </Form.Item>
                        </Space>
                    </Form>
                </Col>
            </Row>

        </>
    );
};

export {Auth};
