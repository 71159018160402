import * as React from "react";
import {useEffect, useState} from "react";
import {useAuth} from "../auth/authProvider";
import {Button, Card, Checkbox, Col, Input, InputNumber, notification, Row, Select, Spin} from "antd";
import axiosAdminInstance from "../../other/api";
import {Currencies} from "../../other/constraints";

const AffiliateSettings = () => {
    const {user, logout} = useAuth();
    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState<any>(null);
    const [hotWallets, setHotWallets] = useState<any[]>([]);
    const [currencies, setCurrencies] = useState<any[]>([]);
    const [api, contextHolder] = notification.useNotification();

    const load = async () => {
        try {
            setLoading(true);
            const res = await axiosAdminInstance.get(`/affiliate/settings`);
            const hotWallets = await axiosAdminInstance.get(`/hotWallet`);
            if (!res.data) {
                setSettings({});
            } else {
                setSettings(res.data);
            }
            
            setHotWallets(hotWallets.data.map((item: any) => {
                return {
                    value: item.id,
                    label: item.publicKey
                }
            }));

            // const currenciesResponse = await axiosAdminInstance.get(`/hotWallet/currencies`);
            // setCurrencies(currenciesResponse.data.map((item: any) => {
            //     return {
            //         value: item.currencyCode,
            //         label: item.currencyCode
            //     }
            // }));

        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    const changeValue = (key: string, value: any) => {
        const record = {...settings};
        record[key] = value;
        setSettings(record);
    }

    const update = async () => {
        try {
            setLoading(true);
            await axiosAdminInstance.put(`/affiliate/settings`, settings);
            api['success']({
                message: 'Saved'
            });
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        load();
    }, []);


    return <div><h3>Settings</h3>  {contextHolder}{!loading && settings &&
        <Row align="top" gutter={16}>
            <Col span={4} style={{padding: 10}}>
                <span>Enabled</span>
                <Checkbox
                    checked={settings.enabled}
                    value={settings.enabled}
                    onChange={e => changeValue('enabled', e.target.checked)}
                />
            </Col>
            <Col span={4} style={{padding: 10}}>
                <span>Payment for sneaker purchase</span>
                <Input
                    style={{width: '100%'}}
                    value={settings.paymentForSneakPurchase}
                    onChange={e => changeValue('paymentForSneakPurchase', e.target.value)}
                />
            </Col>
            <Col span={4} style={{padding: 10}}>
                <span>Payment for headphone purchase</span>
                <Input
                    style={{width: '100%'}}
                    value={settings.paymentForHeadPhonePurchase}
                    onChange={e => changeValue('paymentForHeadPhonePurchase', e.target.value)}
                />
            </Col>
            <Col span={4} style={{padding: 10}}>
                <span>Payment currency</span>
                <Select
                    style={{width: '100%'}}
                    value={settings.paymentCurrency}
                    onChange={e => changeValue('paymentCurrency', e)}
                    options={Currencies}
                />
            </Col>
            <Col span={4} style={{padding: 10}}>
                Payment hot wallet
                <Select
                    style={{width: '100%'}}
                    value={settings.paymentHotWalletId}
                    onChange={e => changeValue('paymentHotWalletId', e)}
                    options={hotWallets}
                />
            </Col>

            <Col span={4} style={{padding: 20}}>
                <Button onClick={update}>Save</Button>
            </Col>

        </Row>}
        {loading && <Spin spinning={true}/>}</div>;
}

export {AffiliateSettings};
