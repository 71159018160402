import * as React from "react";
import {
    Button,
    Checkbox, Col,
    Input,
    notification, Row,
} from "antd";
import {useEffect, useState} from "react";
import {useAuth} from "../auth/authProvider";
import axiosAdminInstance from "../../other/api";

const AppVersion = () => {
    const {user, logout} = useAuth();
    const [loading, setLoading] = useState(false);
    const [appVersions, setAppVersions] = useState<any>({iosVersion: '', androidVersion: ''});
    const [api, contextHolder] = notification.useNotification();


    const load = async () => {
        try {
            setLoading(true);
            const appVers = await axiosAdminInstance.get(`/app-versions`);
            if (appVers)
                setAppVersions(appVers);
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    const update = async () => {
        try {
            setLoading(true);
            await axiosAdminInstance.put(`/app-versions`, appVersions);
            api['success']({
                message: 'Saved'
            });
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        load();
    }, []);

    const changeValue = (key: string, value: any) => {
        const record = {...appVersions};
        record[key] = value;
        setAppVersions(record);
    }
    return <>
        <h2>App versions </h2>
        {contextHolder}
        {appVersions &&
            <div>
             
                <Row justify="center" align="top">
                    <Col span={4} style={{padding: 10}}>
                        <div>
                            <span>Ios version</span>
                            <Input
                                placeholder="Ios version"
                                value={appVersions.iosVersion}
                                onChange={e => changeValue('iosVersion', e.target.value)}
                            />
                        </div>
                    </Col>
                    <Col span={4} style={{padding: 10}}>
                        <div>
                            <span>Android version</span>
                            <Input
                                placeholder="Android version"
                                value={appVersions.androidVersion}
                                onChange={e => changeValue('androidVersion', e.target.value)}
                            />
                        </div>
                    </Col>

                </Row>
                <Row justify="center" align="top">
                    <Col span={4} style={{padding: 10}}>
                        <Button loading={loading} onClick={update}>
                            Save
                        </Button>
                    </Col>
                </Row>


            </div>}
    </>;
}

export {AppVersion};
