import * as React from "react";
import {useEffect, useState} from "react";
import {useAuth} from "../auth/authProvider";
import {Card, Col, Row, Spin} from "antd";

const Dashboard = () => {
    const {user, logout} = useAuth();
    const [loading, setLoading] = useState(false);
    const [stats, setStats] = useState<any>(null);

    const loadStats = async () => {
        try {
            setLoading(true);
            // const res = await getAsync(`/dashboard/stats`);
            // const json = await res.json();
            // setStats(json);
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadStats();
    }, []);


    return <div><h2>Dashboard</h2>{!loading && stats && <Row gutter={16}>
        
    </Row>}
        {loading && <Spin spinning={true}/>}</div>;
}

export {Dashboard};
