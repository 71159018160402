import {createContext, useContext, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "./useLocalStorage";

const AuthContext = createContext({
    user: {token: null, refreshToken: null},
    login: (token: any, refreshToken: string) => {
    }, logout: () => {
    }
});

// @ts-ignore
export const AuthProvider = ({children}) => {
    const [user, setUser] = useLocalStorage("user", null);
    const navigate = useNavigate();

    // call this function when you want to authenticate the user
    const login = async (token: any, refreshToken: string) => {
        setUser({token, refreshToken});
        navigate("/dashboard");
    };

    // call this function to sign out logged in user
    const logout = () => {
        setUser(null);
        navigate("/", {replace: true});
    };

    const value = useMemo(
        () => ({
            user,
            login,
            logout
        }),
        [user]
    );

    // @ts-ignore
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
