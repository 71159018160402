import * as React from "react";
import {useEffect, useState} from "react";
import {useAuth} from "../auth/authProvider";
import {
    Button,
    Card,
    Col,
    Divider, Input,
    InputNumber,
    Modal,
    notification,
    Popconfirm,
    Row,
    Select,
    Space,
    Spin,
    Table
} from "antd";
import axiosAdminInstance from "../../other/api";
import type {ColumnsType} from "antd/es/table";
import {DeleteOutlined} from "@ant-design/icons";
import {Chains, Currencies} from "../../other/constraints";

const HotWallets = () => {
    const {user, logout} = useAuth();
    const [loading, setLoading] = useState(false);
    const [wallets, setWallets] = useState<any>([]);
    const [currencies, setCurrencies] = useState<any>([]);
    const [editCurrency, setEditCurrency] = useState<any>(null);
    const [editWallet, setEditWallet] = useState<any>(null);
    const [api, contextHolder] = notification.useNotification();

    const currencyColumns: ColumnsType<any> = [
        {
            title: 'Currency',
            dataIndex: 'currencyCode',
            key: 'currencyCode'
        },
        {
            title: 'Decimals',
            dataIndex: 'decimals',
            key: 'decimals'
        },
        {
            title: 'Contract address',
            dataIndex: 'contractAddress',
            key: 'contractAddress'
        },
        {
            title: 'Chain',
            dataIndex: 'chainId',
            key: 'chainId',
            render: (record) => <span>{Chains.find(c => c.value == record)?.label}</span>
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space size="middle">
                    <a onClick={() => setEditCurrency(record)}>Edit</a>

                    <Popconfirm
                        title="Delete"
                        description="Are you sure?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={async () => await deleteCurrency(record.currencyCode, record.chainId)}
                    >
                        <Button
                            danger
                            icon={<DeleteOutlined/>}
                        >

                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    const columns: ColumnsType<any> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Private key',
            dataIndex: 'privateKey',
            key: 'privateKey'
        },
        {
            title: 'Public key',
            dataIndex: 'publicKey',
            key: 'publicKey'
        },
        {
            title: 'Chain',
            dataIndex: 'chainId',
            key: 'chainId',
            render: (record) => <span>{Chains.find(c => c.value == record)?.label}</span>
        },
        {
            title: 'Rpc url',
            dataIndex: 'rpcUrl',
            key: 'rpcUrl'
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space size="middle">
                    <a onClick={() => setEditWallet(record)}>Edit</a>

                    <Popconfirm
                        title="Delete"
                        description="Are you sure?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={async () => await deleteWallet(record.id)}
                    >
                        <Button
                            danger
                            icon={<DeleteOutlined/>}
                        >

                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const changeValue = (key: string, value: any) => {
        const record = {...editWallet};
        record[key] = value;
        setEditWallet(record);
    }

    const changeCurrencyValue = (key: string, value: any) => {
        const record = {...editCurrency};
        record[key] = value;
        setEditCurrency(record);
    }

    const update = async () => {
        try {
            setLoading(true);
            await axiosAdminInstance.put(`/hotWallet`, editWallet);
            api['success']({
                message: 'Saved'
            });
            setEditWallet(null);
            await load();
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    const updateCurrency = async () => {
        try {
            setLoading(true);
            await axiosAdminInstance.put(`/hotWallet/currencies`, editCurrency);
            api['success']({
                message: 'Saved'
            });
            setEditCurrency(null);
            await load();
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    const deleteWallet = async (id: any) => {
        try {
            setLoading(true);
            await axiosAdminInstance.delete(`/hotWallet/${id}`);
            api['success']({
                message: 'Deleted'
            });
            await load();
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    const deleteCurrency = async (currencyCode: any, chainId: number) => {
        try {
            setLoading(true);
            await axiosAdminInstance.delete(`/hotWallet/currencies/${chainId}/${currencyCode}`);
            api['success']({
                message: 'Deleted'
            });
            await load();
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    const load = async () => {
        try {
            setLoading(true);
            const res = await axiosAdminInstance.get(`/hotWallet`);
            if (!res.data) {
                setWallets([]);
            } else {
                setWallets(res.data);
            }

            const currencies = await axiosAdminInstance.get(`/hotWallet/currencies`);
            setCurrencies(currencies.data);

        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        load();
    }, []);


    return <div><Row justify="center" align="top">
        <h2>Hot wallets <Button onClick={() => setEditWallet({})}>Create</Button></h2>
    </Row>
        <Table scroll={{x: 1500}} rowKey="id" loading={loading} columns={columns} dataSource={wallets}/>
        {editWallet && <Modal
            title={`Edit`}
            open={!!editWallet}
            onOk={update}
            onCancel={() => setEditWallet(null)}
            okButtonProps={{disabled: loading}}
            cancelButtonProps={{disabled: loading}}
        >
            <div>
                <div>
                    <span>Private key</span>
                    <Input
                        style={{width: '100%'}}
                        value={editWallet.privateKey}
                        onChange={e => changeValue('privateKey', e.target.value)}
                    />
                </div>

                <div>
                    <span>Public key</span>
                    <Input
                        style={{width: '100%'}}
                        value={editWallet.publicKey}
                        onChange={e => changeValue('publicKey', e.target.value)}
                    />
                </div>

                <div>
                    <span>Chain</span>
                    <Select
                        style={{width: '100%'}}
                        value={editWallet.chainId}
                        onChange={e => changeValue('chainId', e)}
                        options={Chains}
                    />
                </div>

                <div>
                    <span>Rpc url</span>
                    <Input
                        style={{width: '100%'}}
                        value={editWallet.rpcUrl}
                        onChange={e => changeValue('rpcUrl', e.target.value)}
                    />
                </div>
            </div>
        </Modal>}

        <Row justify="center" align="top">
            <h2>Currencies <Button onClick={() => setEditCurrency({})}>Create</Button></h2>
        </Row>
        <Table scroll={{x: 1500}} rowKey="id" loading={loading} columns={currencyColumns} dataSource={currencies}/>
        {editCurrency && <Modal
            title={`Edit currency`}
            open={!!editCurrency}
            onOk={updateCurrency}
            onCancel={() => setEditCurrency(null)}
            okButtonProps={{disabled: loading}}
            cancelButtonProps={{disabled: loading}}
        >
            <div>
                <div>
                    <span>Currency</span>
                    <Input
                        style={{width: '100%'}}
                        value={editCurrency.currencyCode}
                        onChange={e => changeCurrencyValue('currencyCode', e.target.value)}
                    />
                </div>

                <div>
                    <span>Contract address</span>
                    <Input
                        style={{width: '100%'}}
                        value={editCurrency.contractAddress}
                        onChange={e => changeCurrencyValue('contractAddress', e.target.value)}
                    />
                </div>

                <div>
                    <span>Chain</span>
                    <Select
                        style={{width: '100%'}}
                        value={editCurrency.chainId}
                        onChange={e => changeCurrencyValue('chainId', e)}
                        options={Chains}
                    />
                </div>

                <div>
                    <span>Decimals</span>
                    <InputNumber
                        style={{width: '100%'}}
                        value={editCurrency.decimals}
                        onChange={e => changeCurrencyValue('decimals', e)}
                    />
                </div>
            </div>
        </Modal>}
    </div>;
}

export {HotWallets};
