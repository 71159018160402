export const Currencies = [{
    value: 'USDT',
    label: 'USDT'
}];

export const Chains = [
    {
        value: 1,
        label: 'ETH'
    },
    {
        value: 56,
        label: 'BSC'
    }
];